<template>
  <div>
    <!-- ======= MAIN ======= -->
    <main id="main">
      <!-- ======= BAŞLIK ======= -->
      <section class="intro-single">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="title-single-box">
                <h1 class="title-single">Haberler</h1>
                <span class="color-text-b"
                  >{{ Belediyebilgiler.belediyeadi }} BELEDİYE BAŞKANLIĞI</span
                >
              </div>
            </div>
            <div class="col-md-12 col-lg-4">
              <nav
                aria-label="breadcrumb"
                class="breadcrumb-box d-flex justify-content-lg-end"
              >
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/">Anasayfa</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Haberler
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <!-- ======= BAŞLIK ======= -->

      <!-- ======= HABERLER ======= -->
      <section class="agents-grid grid">
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <div class="row">
                <div
                  v-for="(haber, index) in Haberlist.slice(0, 5)"
                  :key="index"
                  class="carousel-item-a swiper-slide"
                >
                  <div class="testimonials-box">
                    <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="testimonial-img">
                          <img
                            style="object-fit: cover; height: 360px"
                            :src="haber.kapak"
                            alt=""
                            class="img-fluid"
                          />
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="testimonial-author-box">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            fill="currentColor"
                            class="bi bi-megaphone-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z"
                            />
                          </svg>
                          <h5 class="testimonial-author">
                            <a @click="detayagit(haber.haberId)">{{
                              haber.haberAdi
                            }}</a>
                          </h5>
                        </div>

                        <div class="testimonials-content">
                          <p class="testimonial-text">
                            {{ haber.haberAciklamasi.slice(0, 300) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- HABER İTEM SON -->
              </div>
            </div>
            <Sidebar />
          </div>

          <div class="row">
            <div class="col-sm-9">
              <nav class="pagination-a">
                <ul class="pagination justify-content-end">
                  <li class="page-item disabled">
                    <a class="page-link" href="#" tabindex="-1">
                      <span class="bi bi-chevron-left"></span>
                    </a>
                  </li>
                  <li class="page-item active">
                    <a class="page-link" href="#">1</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">2</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">3</a>
                  </li>
                  <li class="page-item next">
                    <a class="page-link" href="#">
                      <span class="bi bi-chevron-right"></span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <!-- ======= HABERLER ======= -->
    </main>
    <!-- ======= MAIN ======= -->
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
export default {
  name: "Haberler",
  data() {
    return {
      Haberler: {},
      Haberlist: [],
      Belediyebilgiler: {},
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Haberler");
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    this.Haberler = this.$store.getters.getHaberler;
    this.Haberlist = Object.values(this.Haberler);
    this.Haberlist.sort(function (b, a) {
      return a.haberId - b.haberId;
    });
  },
  components: {
    Sidebar,
  },
  methods: {
    detayagit(ID) {
      this.$router.push("/HaberDetay/" + ID);
    },
  },
};
</script>

<style>
</style>